import React, { Suspense, useEffect, useState } from "react"
import { PageProps } from "gatsby"
import Styles, { palette } from "./Styles"
import Navigation from "../components/Nav"
import Footer from "../components/Footer"
import { createGlobalStyle, css, ThemeProvider } from "styled-components"
import { useMediaQuery } from "react-responsive"
import { isBrowser } from "../utils/ssr"
import Modal from "../components/Modal"
import CookieSettings, {
  AnalyticsOption,
  CookieOptions,
  defaultCookieOptions,
} from "../components/modals/CookieSettings"
import CookieNotice from "../components/modals/CookieNotice"
import useLocalStorage from "../utils/use-local-storage"
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin"

interface CookieSettings {
  time: number
  saved: boolean
  options: CookieOptions
}

const initialSettings: CookieSettings = {
  time: -1,
  saved: false,
  options: defaultCookieOptions,
}

const Page: React.FC<PageProps> = ({ children, location }) => {
  const [menuClose, setMenuClose] = useState<() => void>(() => {})
  const [showNotice, setShowNotice] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [cookieSettings, setCookieSettings] = useLocalStorage<CookieSettings>(
    "cookie-settings",
    initialSettings
  )

  const theme = useMediaQuery({
    query: "(prefers-color-scheme: dark)",
  })

  useEffect(() => {
    if (!cookieSettings.saved) setShowNotice(true)
  }, [])

  const onCloseNotice = () => {
    setShowNotice(false)

    // Save default options when the user simply
    // closes the modal
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options: defaultCookieOptions,
    })
  }

  const onCloseSettings = () => {
    setShowSettings(false)

    // Save default options when the user simply
    // closes the modal
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options: defaultCookieOptions,
    })
  }

  const onSaveSettings = (options: CookieOptions) => {
    setShowSettings(false)

    if (options[AnalyticsOption.GoogleAnalytics]) {
      localStorage.setItem(GTAG_OPTIN_KEY, true as any)
      if (typeof (window as any).loadGtag == "function")
        (window as any).loadGtag()
    }

    // Save the options we received from the user when
    // save is clicked
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options,
    })
  }

  const onGotoSettings = () => {
    setShowNotice(false)
    setShowSettings(true)
  }

  const onAcceptAll = () => {
    setShowNotice(false)

    localStorage.setItem(GTAG_OPTIN_KEY, true as any)
    if (typeof (window as any).loadGtag == "function")
      (window as any).loadGtag()

    const all = Object.values(AnalyticsOption).reduce(
      (acc, it) => ({ ...acc, [it]: true }),
      {}
    )

    // Put cookies everywhere
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options: all,
    })
  }

  return (
    <ThemeProvider theme={theme ? palette.light : palette.light}>
      <MenuContext.Provider
        value={{
          registerMenuClose: fn => setMenuClose(fn),
          closeMenu: menuClose,
        }}
      >
        <Styles />
        <Navigation />

        <div className="main">{children}</div>

        <Footer />

        {isBrowser && (
          <>
            <Modal isShown={showSettings} showBackdrop disableOutsideClick>
              <CookieSettings
                onClose={() => onCloseSettings()}
                onSave={opts => onSaveSettings(opts)}
              />
            </Modal>

            <Modal isShown={showNotice} showBackdrop disableOutsideClick>
              <CookieNotice
                onClose={() => onCloseNotice()}
                onGotoSettings={() => onGotoSettings()}
                onAcceptAll={() => onAcceptAll()}
              />
            </Modal>
          </>
        )}
      </MenuContext.Provider>
    </ThemeProvider>
  )
}

export const MenuContext = React.createContext({
  registerMenuClose: (_: () => void) => {},
  closeMenu: () => {},
})

export const PreventBodyScroll = createGlobalStyle<{ isAnimating?: boolean }>`
  body, html {
    overflow: hidden;
    
    ${props =>
      props.isAnimating &&
      css`
        pointer-events: none;
      `}
  }

`

export default Page
