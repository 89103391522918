import anime from "animejs"
import React, { RefObject, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import styled, { createGlobalStyle, css } from "styled-components"
import { isBrowser } from "../utils/ssr"
import { bp, colors, themeOptions } from "../layouts/Styles"
import Close from "../assets/icons/close.svg"
import { PreventBodyScroll } from "../layouts"

const Backdrop = styled.div`
  opacity: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
`

const ModalContainer = styled.div<{ alignStart: boolean }>`
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.alignStart &&
    bp("mobile")`
      align-items: flex-start;
      padding-top: 2rem;
    `}

  width: 100%;
  height: 100vh;

  > div {
    background: ${({ theme }) => theme.background.default};
    opacity: 0;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(100vw - 4rem);
    overflow-y: auto;
    max-height: 85vh;

    > div {
      opacity: 0;
    }
  }
`

export const ModalHeader: React.FC<{
  onClose?: () => void
  title?: string
  className?: string
  hideClosingTag?: boolean
}> = ({ onClose = () => {}, title, className, hideClosingTag }) => (
  <ModalHeaderContainer className={className}>
    <h3>{title}</h3>

    {!hideClosingTag && (
      <button className="close-button" onClick={() => onClose()}>
        <Close />
      </button>
    )}
  </ModalHeaderContainer>
)

const ModalHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25em;
  align-items: flex-start;

  h3 {
    font-size: 2.6em;
    padding-top: 0.65em;
    font-weight: 500;
  }

  .close-button {
    border: 1px solid ${({ theme }) => theme.text.light};
    width: 2.6em;
    height: 2.6em;
    border-radius: 50%;
    cursor: pointer;
    transition: ${themeOptions.transition};
    flex-shrink: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 0.72em;
      height: 0.72em;

      polyline {
        stroke: ${({ theme }) => theme.text.light};
      }
    }

    :hover {
      background: ${({ theme }) => theme.text.light};
      polyline {
        stroke: ${({ theme }) => theme.background.paper};
      }
    }
  }
`

interface ModalProps {
  isShown?: boolean
  onClose?: () => void
  showBackdrop?: boolean
  disableOutsideClick?: boolean
  fromElement?: RefObject<HTMLElement | null>
  fromWidth?: number
  fromHeight?: number
  alignStart?: boolean
  children: any
}

const FADE_DURATION = 500
const FROM_ELEM_ANIM_DURATION = 800

const INITIAL_OPACITY_FR = 1 / 6
const BACKDROP_APPEAR_FR = 4 / 6

const MODAL_SCALE_FR = 3 / 6
const MODAL_SCALE_DELAY = 1 / 6

const MODAL_CONTENT_OPACITY_FR = 2 / 6
const MODAL_CONTENT_OPACITY_DELAY = 2 / 6

const Modal: React.FC<ModalProps> = ({
  children,
  onClose = () => {},
  isShown,
  fromElement,
  fromWidth,
  fromHeight,
  disableOutsideClick,
  alignStart = false,
}) => {
  const [wasShown, setWasShown] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const backdropRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const contentInnerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const onContainerClick = (e: Event) => {
    if (e.target !== containerRef.current || disableOutsideClick) return
    onClose()
  }

  useEffect(() => {
    if (!isBrowser) return

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key !== "Escape" || disableOutsideClick) return
      onClose()
    }

    document.addEventListener("keydown", handleKeyPress)
    return () => document.removeEventListener("keydown", handleKeyPress)
  }, [])

  const onShow = () => {
    if (fromElement?.current) {
      const first = fromElement.current.getBoundingClientRect()
      const last = contentRef.current!!.getBoundingClientRect()

      const fwidth = fromWidth ?? first.width - 24
      const fheight = fromHeight ?? first.height - 26

      const dx = first.left - last.left + 12
      const dy = first.top - last.top + 14
      const dw = fwidth / last.width
      const dh = fheight / last.height

      // Set start values
      anime.set(contentRef.current!!, {
        transformOrigin: "top left",
        translateX: dx,
        translateY: dy,
        scaleX: dw,
        scaleY: dh,
        borderRadius: "40px",
      })

      const anim = anime.timeline({
        duration: FROM_ELEM_ANIM_DURATION,
        easing: "easeInOutCubic",
        autoplay: false,
        begin: () => setIsAnimating(true),
        complete: () => setIsAnimating(false),
      })

      // Let backdrop appear at start of animation
      anim.add(
        {
          targets: [backdropRef.current],
          duration: FROM_ELEM_ANIM_DURATION * BACKDROP_APPEAR_FR,
          opacity: 1,
        },
        0
      )

      anim.add(
        {
          targets: [contentRef.current],
          duration: FROM_ELEM_ANIM_DURATION * INITIAL_OPACITY_FR,
          opacity: 1,
        },
        0
      )

      anim.add(
        {
          targets: [contentRef.current],
          duration: FROM_ELEM_ANIM_DURATION * MODAL_SCALE_FR,
          scaleX: 1,
          scaleY: 1,
          translateX: 0,
          translateY: 0,
          opacity: 1,
          borderRadius: "10px",
        },
        FROM_ELEM_ANIM_DURATION * MODAL_SCALE_DELAY
      )

      anim.add(
        {
          targets: [contentInnerRef.current],
          duration: FROM_ELEM_ANIM_DURATION * MODAL_CONTENT_OPACITY_FR,
          opacity: 1,
        },
        FROM_ELEM_ANIM_DURATION * MODAL_CONTENT_OPACITY_DELAY
      )

      anim.play()
    } else {
      const anim = anime.timeline({
        duration: FADE_DURATION,
        easing: "easeInOutCubic",
        autoplay: false,
        begin: () => setIsAnimating(true),
        complete: () => setIsAnimating(false),
      })

      anim.add(
        {
          targets: [backdropRef.current],
          opacity: 1,
        },
        0
      )

      anim.add(
        {
          targets: [contentRef.current],
          opacity: 1,
        },
        0
      )

      anim.add(
        {
          targets: [contentInnerRef.current],
          duration: (FADE_DURATION * 3) / 4,
          opacity: 1,
        },
        (FADE_DURATION * 1) / 4
      )

      anim.play()
    }
  }

  const onHide = () => {
    const anim = anime.timeline({
      duration: FADE_DURATION,
      easing: "easeInOutCubic",
      autoplay: false,
      begin: () => setIsAnimating(true),
      complete: () => {
        setIsAnimating(false)
        setIsMounted(false)
      },
    })

    anim.add(
      {
        targets: [backdropRef.current],
        opacity: 0,
      },
      0
    )

    anim.add(
      {
        targets: [contentRef.current],
        opacity: 0,
      },
      0
    )

    anim.play()
  }

  useEffect(() => {
    if (!wasShown && isShown) {
      setWasShown(true)
      setIsMounted(true)
    } else if (wasShown && !isShown) {
      setWasShown(false)
      onHide()
    }
  }, [isShown])

  useEffect(() => {
    if (isMounted) onShow()
  }, [isMounted])

  if (!isBrowser) return null

  return ReactDOM.createPortal(
    isMounted ? (
      <>
        <PreventBodyScroll isAnimating={isAnimating} />

        <Backdrop ref={backdropRef} />

        <ModalContainer
          onClick={e => onContainerClick(e as any)}
          ref={containerRef}
          {...{ alignStart }}
        >
          <div ref={contentRef}>
            <div ref={contentInnerRef}>{children}</div>
          </div>
        </ModalContainer>
      </>
    ) : null,
    document.body
  )
}

export default Modal
